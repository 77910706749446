import { createReducer, on } from '@ngrx/store';
import * as fromSharedActions from '../actions/shared.actions';

export const featureKey = 'shared';

export interface State {
  showLoading: boolean;
  errorMessage: string;
  accessToken: string;
  processor: any;
  transactionDetails: any;
  depositProcessors: Array<any>;
  withdrawProcessors: Array<any>;
  sendTo: string;
  recipient: any;
}

export const initialState: State = {
  showLoading: false,
  errorMessage: '',
  accessToken: 'test',
  processor: null,
  transactionDetails: null,
  depositProcessors: [],
  withdrawProcessors: [],
  sendTo: '',
  recipient: null
};

export const reducer = createReducer(
  initialState,
  on(fromSharedActions.setLoadingSpinner, (state: State, props: any) => ({
    ...state,
    showLoading: props.status,
  })),
  on(fromSharedActions.setErrorMessage, (state: State, props: any) => ({
    ...state,
    errorMessage: props.message,
  })),
  on(fromSharedActions.setAccessToken, (state: State, props: any) => ({
    ...state,
    accessToken: props.data,
  }))
);
