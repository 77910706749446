import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { effects } from './store/effects';
import { reducers } from './store/reducers';
import { CustomSerializer } from './store/router/custom-route-serializer';
import { NgOtpInputModule } from 'ng-otp-input';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { TokenInterceptor, ErrorInterceptor } from './interceptor/token.interceptor';
import { CommonModule } from '@angular/common';
import '@capacitor-community/camera-preview'
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { LazyLoadImageModule, } from 'ng-lazyload-image';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent, LoadingSpinnerComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    NgOtpInputModule,
    LazyLoadImageModule
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    InAppBrowser,
    HTTP,
    Market,
    IonIntlTelInputModule,
    SocialSharing,
    CookieService
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
