import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, tap, catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error.service';
import { AppState } from '../app.state';
import * as fromSharedActions from '../actions/shared.actions';
import { SharedService } from 'src/app/services/shared.service';
import { ModalController, NavController } from '@ionic/angular';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { getCurrentRoute } from '../selectors/router.selector';
import { of } from 'rxjs';
import { NavigationExtras } from '@angular/router';
import { ErrorComponent } from 'src/app/shared/modals/error/error.component';
import { setAccessToken } from '../actions/shared.actions';
import { Browser } from '@capacitor/browser';
import { OfflineStorageService } from 'src/app/services/offline-storage.service';

@Injectable()
export class SharedEffects {

  checkoutRedirect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/checkout')
      }),
      withLatestFrom(this.store.select(getCurrentRoute), (action, currentRoute) => {
        const transactionType = 'checkout';
        const expires = currentRoute?.queryParams?.expires || null;
        const accessToken = currentRoute?.queryParams?.token || null;
        const requestReferenceNumber = currentRoute?.queryParams?.requestReferenceNumber || null;
        const signature = currentRoute?.queryParams?.signature || null;

        console.log({params: currentRoute?.queryParams})
        if (expires && requestReferenceNumber && signature) {
          this.sharedService.setUserSession(accessToken);
          this.store.dispatch(setAccessToken({data: accessToken}));
          return { ...action, isValid: true, data: {expires, accessToken, requestReferenceNumber, signature, transactionType}};
        }
        return { ...action, isValid: false, data: null};
      }),
      mergeMap((action) => {
        console.log('test', action)
        if (action?.isValid && action?.data) {
          return of(fromSharedActions.redirectTo({ data: action?.data }));
        } else {
          return of(fromSharedActions.failedTransaction({ data: 'notfound' }));
        }
      })
    );
  });

  // redirectTo$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromSharedActions.redirectTo),
  //       tap(async (action) => {
  //         console.log('redirectTo$', action?.data);
  //         if (action?.data?.transactionType === 'checkout') {
  //           const queryParams = {...action?.data};
  //           delete queryParams.transactionType;
  //           delete queryParams.accessToken;
  //           const navigationExtras: NavigationExtras = {
  //             queryParams
  //           }
  //           this.navCtrl.navigateRoot('/checkout', navigationExtras);
  //         } else {
  //           this.navCtrl.navigateRoot('notfound');
  //         }
  //       })
  //     ),
  //   { dispatch: false }
  // );

  processOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSharedActions.processOrder),
      exhaustMap((action) =>
        this.sharedService.proceedToOrder(action.data).pipe(
          map((response: any) => {
            this.store.dispatch(fromSharedActions.setLoadingSpinner({ status: false }));
            return fromSharedActions.processOrderSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('processOrder$', response);
            this.store.dispatch(fromSharedActions.setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromSharedActions.failedTransaction({ data: error }));
          })
        )
      )
    )
  );

  processOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromSharedActions.processOrderSuccess),
        tap(async (action) => {
          console.log('processOrderSuccess$', action.data);
          this.offlineStorageService.removeItem('payload');
          const redirectUrl = action?.data?.redirectUrl;
          await Browser.open({ url: redirectUrl, windowName: "_self" });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private offlineStorageService: OfflineStorageService
  ) {}

}
