/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContextToken
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { getAccessToken } from '../store/selectors/shared.selector';
export const SKIP = new HttpContextToken(() => false);
export const BEARER_ONLY = new HttpContextToken(() => false);
export const BYPASS_HEAD = new HttpContextToken(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(getAccessToken).pipe(
      take(1),
      exhaustMap((token) => {
        if (request.context.get(BYPASS_HEAD) === true) {
          return next.handle(request);
        } else if (request.context.get(BEARER_ONLY) === true) {
          const modifiedReq = request.clone({
            setHeaders: {             
              'Authorization': `Bearer ${token}`,
            },
          });
          return next.handle(modifiedReq);
        }
        
        if (!token || request.context.get(SKIP) === true) {
          const modifiedReq = request.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json'
            },
          });
          return next.handle(modifiedReq);
        }  else {
          const modifiedReq = request.clone({
            setHeaders: {
              'Content-Type' : 'application/json; charset=utf-8',
              'Accept'       : 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          return next.handle(modifiedReq);
        }
        // if (!token) {
        //   const modifiedReq = request.clone({
        //     setHeaders: {
        //       'Content-Type' : 'application/json; charset=utf-8',
        //       'Accept'       : 'application/json'
        //     },
        //   });
        //   return next.handle(modifiedReq);
        // } else {
        //   const modifiedReq = request.clone({
        //     setHeaders: {
        //       'Content-Type' : 'application/json; charset=utf-8',
        //       'Accept'       : 'application/json',
        //       'Authorization': `Bearer ${token}`,
        //     },
        //   });
        //   return next.handle(modifiedReq);
        // }
      })        
    );
  }
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response) => {
        if (response.status === 401) {
          console.log('ErrorInterceptor timeout', new Date());
          window.dispatchEvent(new CustomEvent('auth:timeout', { detail: true}));  
        }

        if (!navigator.onLine || response.status <= 0) {
          const errorResponse = {
            error: {
              message: "Slow or no internet connections. Please check your internet and try again."
            },
            status: 12001, // Out of Handles - No more internet handles could be generated at this time.
            headers: response.headers,
            url: response.url,
          };
          return throwError(errorResponse);
        }

        return throwError(response);
      })
    );
  }
}
