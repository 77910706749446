import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromShared from '../reducers/shared.reducer';

export const selectState = createFeatureSelector<fromShared.State>(
  fromShared.featureKey
);

export const getLoading = createSelector(selectState, (state) => state.showLoading);
export const getErrorMessage = createSelector(selectState, (state) => state.errorMessage);
export const getAccessToken = createSelector(selectState, (state) => state.accessToken);
export const getProcessor = createSelector(selectState, (state) => state.processor);
export const getTransactionDetails = createSelector(selectState, (state) => state.transactionDetails);
export const getDepositProcessors = createSelector(selectState, (state) => state.depositProcessors);
export const getWithdrawProcessors = createSelector(selectState, (state) => state.withdrawProcessors);
export const getSendTo = createSelector(selectState, (state) => state.sendTo);
export const getRecipient = createSelector(selectState, (state) => state.recipient);
