import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'track',
    pathMatch: 'full'
  },
  {
    path: 'track',
    loadChildren: () => import('./pages/track/track.module').then( m => m.TrackPageModule)
  },
  {
    path: 'track/:trackingReferenceNumber',
    loadChildren: () => import('./pages/tracking-details/tracking-details.module').then( m => m.TrackingDetailsPageModule)
  },
  {
    path: 'notfound',
    loadChildren: () => import('./pages/notfound/notfound.module').then( m => m.NotfoundPageModule)
  },
  { path: '**', redirectTo: '/notfound' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    NgOtpInputModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
