import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BEARER_ONLY, BYPASS_HEAD } from '../interceptor/token.interceptor';

const USER_SESSION = '__SECURE_SPSMAPH';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  async setUserSession(accessToken) {
    this.cookieService.set(USER_SESSION, accessToken, null, null, null, true);
  }

  async getUserSession() {
    const accessToken: any = await this.cookieService.get(USER_SESSION);
    if (accessToken) {
      return accessToken;
    }
    return null;
  }

  checkout(item): Observable<any> {
    return this.http
    .post(environment.apiUrl + '/api/partners/v1/delivery/order-via-xendit', item, { context: new HttpContext().set(BEARER_ONLY, true) });
  }

  getTransactionDetails(param): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/api/partners/v1/delivery/order?expires=${param?.expires}&requestReferenceNumber=${param?.requestReferenceNumber}&signature=${param?.signature}`);
  }

  proceedToOrder(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/partners/v1/delivery/order', JSON.stringify(item));
  }

  trackOrder(trackingNo){
    return this.http
      .get<any>(environment.apiUrl + `/api/partners/v1/track/${trackingNo}`);
  }
}
