import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  getRegions(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/api/v1/address/regions');
  }

  getProvince(regCode): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/api/v1/address/provinces/${regCode}`);
  }

  getMunicity(provCode): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/api/v1/address/cities/${provCode}`);
  }

  getBarangays(cityMunCode): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/api/v1/address/barangays/${cityMunCode}`);
  }
}
