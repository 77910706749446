import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor() { }

  getErrorMessage(response) {
    if (response.status === 400 || response.status === 401 || response.status === 12001) {
      return response.error.message;
    } else if (response.status === 422) {
      const errorArr = [];
      const validation_errors = response.error.errors;
      for(const field of Object.keys(validation_errors)){
        errorArr.push(validation_errors[field][0]);
      }
      return errorArr;
    }
    return 'Unknown error occured, Please try again.';
  }
}
