/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_REGION = '[Shared] Get Region',
  GET_REGION_SUCCESS = '[Shared] Get Region Success',
  GET_PROVINCE = '[Shared] Get Province',
  GET_PROVINCE_SUCCESS = '[Shared] Get Province Success',
  GET_MUNICITY = '[Shared] Get Municity',
  GET_MUNICITY_SUCCESS = '[Shared] Get Municity Success',
  GET_BARANGAY = '[Shared] Get Barangay',
  GET_BARANGAY_SUCCESS = '[Shared] Get Barangay Success',
}

export const getRegion = createAction(
  ActionTypes.GET_REGION
);

export const getRegionSuccess = createAction(
  ActionTypes.GET_REGION_SUCCESS,
  props<{ data: any }>()
);

export const getProvince = createAction(
  ActionTypes.GET_PROVINCE,
  props<{ data: any }>()
);

export const getProvinceSuccess = createAction(
  ActionTypes.GET_PROVINCE_SUCCESS,
  props<{ data: any }>()
);

export const getMunicity = createAction(
  ActionTypes.GET_MUNICITY,
  props<{ data: any }>()
);

export const getMunicitySuccess = createAction(
  ActionTypes.GET_MUNICITY_SUCCESS,
  props<{ data: any }>()
);

export const getBarangay = createAction(
  ActionTypes.GET_BARANGAY,
  props<{ data: any }>()
);

export const getBarangaySuccess = createAction(
  ActionTypes.GET_BARANGAY_SUCCESS,
  props<{ data: any }>()
);