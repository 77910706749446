import { createReducer, on } from '@ngrx/store';
import * as fromAddress from '../actions/address.actions';

export const featureKey = 'address';

export interface State {
    region: {
        data: any,
        isLoaded: Boolean
    };
    provinces: {
        data: any,
        isLoaded: Boolean
    };
    cities: {
        data: any,
        isLoaded: Boolean
    };
    barangays: {
        data: any,
        isLoaded: Boolean
    };
}

export const initialState: State = {
    region: {
        data: null,
        isLoaded: false
    },
    provinces: {
        data: null,
        isLoaded: false
    },
    cities: {
        data: null,
        isLoaded: false
    },
    barangays: {
        data: null,
        isLoaded: false
    },
}

export const reducer = createReducer(
    initialState,
    on(fromAddress.getRegionSuccess, (state: State, props: any) => ({
        ...state,
        region: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getProvinceSuccess, (state: State, props: any) => ({
        ...state,
        provinces: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getMunicitySuccess, (state: State, props: any) => ({
        ...state,
        cities: {data: props.data, isLoaded: true}
    })),
    on(fromAddress.getBarangaySuccess, (state: State, props: any) => ({
        ...state,
        barangays: {data: props.data, isLoaded: true}
    })),
);