import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { getLoading } from './store/selectors/shared.selector';
import { setAccessToken } from './store/actions/shared.actions';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  dark = false;

  showLoading$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private platform: Platform,
    private sharedService: SharedService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    console.log('AppComponent ngOnInit');
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.showLoading$ = this.store.select(getLoading);
      const accessToken = await this.sharedService.getUserSession();
      if (accessToken) {
        this.store.dispatch(setAccessToken({data: accessToken}));
      }
    });
  }
}
