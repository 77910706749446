import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, tap, catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { AddressService } from 'src/app/services/address.service';
import * as fromAddressActions from '../actions/address.actions'
import { APIResponse } from 'src/app/models/api-response.model';

@Injectable()
export class AddressEffects {

  getRegion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/checkout')
        || r.payload.routerState.url.startsWith('/checkout-address');
      }),
      mergeMap(() => {
        return this.addressService.getRegions().pipe(
          map((response: APIResponse) => {
            console.log('getRegion$', response);
            return fromAddressActions.getRegionSuccess({ data: response});
          })
        );
      })
    );
  });

  getRefProvince$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAddressActions.getProvince),
      mergeMap((action) => {
        return this.addressService.getProvince(action?.data).pipe(
          map((response: APIResponse) => {
            console.log('getRefProvince$', response);
            return fromAddressActions.getProvinceSuccess({ data: response});
          })
        );
      })
    )
  });

  getMunicity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAddressActions.getMunicity),
      exhaustMap((action) => {
        return this.addressService.getMunicity(action?.data).pipe(
          map((response: APIResponse) => {           
            console.log('getMunicity$', response);
            return fromAddressActions.getMunicitySuccess({ data: response});
          })
        );
      })
    )
  });

  getBarangay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAddressActions.getBarangay),
      exhaustMap((action) => {
        return this.addressService.getBarangays(action?.data).pipe(
          map((response: APIResponse) => {           
            console.log('getBarangay$', response);
            return fromAddressActions.getBarangaySuccess({ data: response});
          })
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private addressService: AddressService
  ) {}

}
