/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  SET_LOADING_SPINNER = '[Shared] set loading spinner',
  SET_ERROR_MESSAGE = '[Shared] set error message',
  REDIRECT_TO = '[Shared] Redirect to',
  FAILED_TRANSACTION = '[Shared] Failed Transaction',
  SET_ACCESS_TOKEN = '[Shared] set access token',
  SET_PROCESSOR = '[Shared] set processor',
  GET_TRANSACTION_DETAILS = '[Shared] Get Transaction Details',
  GET_TRANSACTION_DETAILS_SUCCESS = '[Shared] Get Transaction Details Success',
  GET_DEPOSIT_PROCESSORS = '[Shared] Get Deposit Processors',
  GET_DEPOSIT_PROCESSORS_SUCCESS = '[Shared] Get Deposit Processors Success',
  GET_WITHDRAW_PROCESSORS = '[Shared] Get Withdraw Processors',
  GET_WITHDRAW_PROCESSORS_SUCCESS = '[Shared] Get Withdraw Processors Success',
  GENERATE_OTP = '[Shared] Generate OTP',
  GENERATE_OTP_SUCCESS = '[Shared] Generate OTP Success',
  PROCESS_DEPOSIT = '[Shared] Process Deposit',
  PROCESS_DEPOSIT_SUCCESS = '[Shared] Process Deposit Success',
  PROCESS_WITHDRAW = '[Shared] Process Withdraw',
  PROCESS_WITHDRAW_SUCCESS = '[Shared] Process Withdraw Success',
  SET_RECIPIENT = '[Shared] set recipient',

  PROCESS_ORDER = '[Shared] Process Order',
  PROCESS_ORDER_SUCCESS = '[Shared] Process Order Success',
}

export const setLoadingSpinner = createAction(
  ActionTypes.SET_LOADING_SPINNER,
  props<{ status: boolean }>()
);

export const setErrorMessage = createAction(
  ActionTypes.SET_ERROR_MESSAGE,
  props<{ message: string }>()
);

export const redirectTo = createAction(
  ActionTypes.REDIRECT_TO,
  props<{ data: any }>()
);

export const failedTransaction = createAction(
  ActionTypes.FAILED_TRANSACTION,
  props<{ data: any }>()
);

export const setAccessToken = createAction(
  ActionTypes.SET_ACCESS_TOKEN,
  props<{ data: string }>()
);

export const processOrder = createAction(
  ActionTypes.PROCESS_ORDER,
  props<{ data: any }>()
);

export const processOrderSuccess = createAction(
  ActionTypes.PROCESS_ORDER_SUCCESS,
  props<{ data: any }>()
);